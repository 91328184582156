<template>
	<div class="wrapper">
		<h4>Co-owner:</h4>
		<div class="section">
			<a href="https://portuj.pl" target="_blank">portuj.pl</a>
			<!-- <a href="https://amaranthapp.com" target="_blank">Amaranth</a> -->
			<!-- <a href="#" target="_blank">Whiteboard</a> -->
		</div>

		<h4>Commercial:</h4>
		<div class="section">
			<a href="https://di-daily.com" target="_blank">
				di-daily
			</a>
			<a href="https://lablearningandbeing.pl" target="_blank">
				LAB
			</a>
			<a href="https://kuestionmark.pl" target="_blank">
				Kuestionmark
			</a>
			<a href="https://eqstudio.pl" target="_blank">
				EQstudio
			</a>
			<a href="https://equiclub.pl" target="_blank">
				Equiclub
			</a>
			<a href="https://masterklasa.pl" target="_blank">
				Masterklasa
			</a>
			<a href="https://michalgruszczynski.com" target="_blank">
				Michał Gruszczyński
			</a>
			<!-- <a href="http://jakubhajduk.com" target="_blank">Jakub Hajduk</a> -->
			<a href="https://agitated-ride-d48bc3.netlify.com" target="_blank">
				Marek Paduch
			</a>
			<!-- <a href="https://zgubinska.pl" target="_blank">Zgubińska</a> -->
			<!-- <a href="http://spolem.pila.pl" target="_blank">
				Społem Piła
			</a> -->
			<!-- <a href="https://formatv.pl" target="_blank">
				Forma TV
			</a> -->
			<!-- <a href="http://michaelstarling.eu" target="_blank">
				Michael Starling Dreamer Tour
			</a> -->
			<!-- <a href="http://picturesfrompoland.com/" target="_blank">
				Pictures from Poland
			</a>
			<a href="https://jtcmedia.eu" target="_blank">
				JTC Media
			</a> -->
			<!-- <a href="https://www.lotto.pl/szybkie-600/zobacz-losowania" target="_blank">
				Lotto lottery simulator
			</a> -->

			<a href="http://aestate.pl" target="_blank">Aestate</a>
			<a href="https://cleanfoods.pl/" target="_blank">Clean Foods</a>
			<!-- <a href="https://a2mbio.com/" target="_blank">A2MBio</a> -->
			<a href="https://fhuanex.pl/" target="_blank">fhu Anex</a>
		</div>

		<h4>Fun:</h4>
		<div class="section">
			<a href="https://modest-brahmagupta-f436f4.netlify.com" target="_blank">
				Sailing Landscape
			</a>
			<a
				href="https://play.google.com/store/apps/details?id=com.chlewicki.MeteorBoom"
				target="_blank"
			>
				Meteor Boom
			</a>
			<a href="https://chlewicki.github.io/canvas/" target="_blank">Canvas</a>
			<a href="https://chlewicki.github.io/vue-pokemonbattle/" target="_blank">
				Pokemon battle
			</a>
			<a href="https://chlewicki.github.io/timetohex/" target="_blank">
				Timed background
			</a>
		</div>
	</div>
</template>
<script>
export default {}
</script>
<style scoped>
.wrapper {
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 99;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
a {
	color: #000;
	text-decoration: none;
	margin: 5px;
}
a:hover {
	text-decoration: underline;
}
.section {
	margin: 10px;
	margin-bottom: 30px;
}
</style>
