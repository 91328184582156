<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	methods: {}
}
</script>

<style>
body {
	background: #f2f2f2;
	overflow: hidden;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	--author-color: #232526;
	font-family: 'Lato', sans-serif;
}
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	height: 100vh;
	position: relative;
}
.scalable {
	transition: all 0.3s ease-in-out;
	position: fixed;
	z-index: 98;
	border-radius: 50%;
}
.cross {
	position: fixed;
	top: 0;
	right: 0;
	margin: 10px;
	cursor: pointer;
	z-index: 100;
}
.hide {
	display: none;
}
@media (max-width: 720px) {
	body {
		overflow: visible;
	}
	.row:nth-of-type(1) .box {
		left: 2% !important;
	}
	.row:nth-of-type(2) .box {
		left: 18% !important;
	}
	.row:nth-of-type(3) .box {
		left: 50% !important;
	}
}
</style>
