<template>
	<div class="row" @click.self="moveBox($event)">
		<div :style="styleObject" @click="zoomIn($event)" class="box">{{ box }}</div>
		<cross class="hide" @close="emitClose"></cross>
	</div>
</template>
<script>
import Cross from './Cross.vue'
import { eventBus } from '../main'

export default {
	props: ['inside'],
	data() {
		return {
			box: this.inside,
			styleObject: {
				width: 0,
				height: 0,
				top: 0,
				left: 0
			}
		}
	},
	components: {
		cross: Cross
	},
	methods: {
		randomNumber(min, max) {
			return Math.floor(Math.random() * max) + min
		},
		randomValues() {
			this.randomSize()
			this.styleObject.top = this.randomNumber(0, 10) + '%'
			this.styleObject.left = this.randomNumber(10, 30) + '%'
		},
		randomSize() {
			let size = this.randomNumber(100, 200) + 'px'
			this.styleObject.width = size
			this.styleObject.height = size
		},
		zoomIn($event) {
			let target = $event.target
			let div = document.createElement('div')
			div.classList.add('scalable')
			div.style.backgroundColor = getComputedStyle(target).backgroundColor
			div.setAttribute('width', target.getBoundingClientRect().width)
			div.setAttribute('height', target.getBoundingClientRect().height)
			div.setAttribute('top', target.getBoundingClientRect().top)
			div.setAttribute('left', target.getBoundingClientRect().left)

			div.style.width = div.getAttribute('width') + 'px'
			div.style.height = div.getAttribute('height') + 'px'
			div.style.top = div.getAttribute('top') + 'px'
			div.style.left = div.getAttribute('left') + 'px'
			document.querySelector('#app').appendChild(div)
			setTimeout(function() {
				div.style.top = '0'
				div.style.left = '0'
				div.style.width = '100vw'
				div.style.height = '100vh'
				div.style.borderRadius = '0'
			}, 100)
			setTimeout(function() {
				let cross = target.nextElementSibling
				cross.classList.remove('hide')
			}, 400)

			this.$emit('sendContent', this.box)
		},
		emitClose() {
			this.$emit('closedModule')
		},
		moveBox($event) {
			$event.target.firstChild.style.left =
				$event.clientX -
				$event.target.firstChild.getBoundingClientRect().width / 2 +
				'px'
		}
	},
	created() {
		this.randomValues()

		eventBus.$on('clickShuffle', () => {
			this.randomValues()
		})
	},
	mounted: function() {
		this.$nextTick(function() {
			this.$emit('checkColor')
		})
	}
}
</script>
<style scoped>
.row {
	width: 100%;
	height: 20%;
	position: relative;
}
.box {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.3s ease-in-out;
	border-radius: 50%;
	cursor: pointer;
	z-index: 9;
	font-weight: 700;
}
.box:hover {
	z-index: 10;
}
@media (max-width: 720px) {
	.row {
		height: 350px;
	}
	.box {
		width: 150px !important;
		height: 150px !important;
	}
}
</style>
