<template>
	<div>
		<img src="../assets/cross.png" @click="zoomOut($event)" class="cross" />
	</div>
</template>
<script>
export default {
	methods: {
		zoomOut($event) {
			this.$emit('close')
			let parent = document.querySelector('.scalable')
			$event.target.parentElement.classList.add('hide')
			parent.style.width = parent.getAttribute('width') + 'px'
			parent.style.height = parent.getAttribute('height') + 'px'
			parent.style.top = parent.getAttribute('top') + 'px'
			parent.style.left = parent.getAttribute('left') + 'px'
			parent.style.borderRadius = '50%'
			setTimeout(function() {
				parent.parentElement.removeChild(parent)
			}, 300)
		}
	}
}
</script>
