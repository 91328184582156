<template>
	<div class="wrapper">
		<h4>Fluent:</h4>
		<div class="section">
			<p>Vue.js</p>
			<p>Nuxt.js</p>
			<p>Javascript</p>
			<p>HTML5</p>
			<p>CSS3</p>
			<p>Ajax</p>
			<p>Git</p>
			<p>RWD</p>
			<p>Photoshop</p>
		</div>

		<h4>Mastering:</h4>
		<div class="section">
			<p>TypeScript</p>
			<p>Node.js</p>
			<p>UI/UX</p>
		</div>

		<h4>Minor knowledge:</h4>
		<div class="section">
			<p>PHP</p>
			<p>SQL</p>
			<p>Powershell</p>
			<p>C++</p>
			<p>C#</p>
			<p>Unity</p>
		</div>
	</div>
</template>
<script>
export default {}
</script>
<style scoped>
.wrapper {
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 99;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
p {
	color: #000;
	margin: 5px;
}
p:hover {
	text-decoration: underline;
}
.section {
	margin: 10px;
	margin-bottom: 30px;
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}
</style>
