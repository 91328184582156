import Home from '../components/Home.vue'
import Projects from '../components/Projects.vue'

export const routes = [
	{
		path: '/',
		component: Home,
		name: 'home'
	},
	{
		path: '/projects',
		component: Projects,
		name: 'projects'
	}
]
