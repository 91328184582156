<template>
	<div class="wrapper">
		<h4>Contact me:</h4>
		<div class="section">
			<p>
				<a href="mailto:chlewicki@@gmail.com" class="mail">chlewicki@gmail.com</a>
			</p>
			<p><a href="tel:+48604642775">+48 604 642 775</a></p>
		</div>

		<h4>Social:</h4>
		<div class="section">
			<p><a href="https://facebook.com/chlewicki">Facebook</a></p>
			<p><a href="https://www.linkedin.com/in/chlewicki/">LinkedIn</a></p>
			<p><a href="https://github.com/chlewicki">GitHub</a></p>
		</div>
	</div>
</template>
<script>
export default {}
</script>
<style scoped>
.wrapper {
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 99;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
p,
a {
	color: #000;
	margin: 5px;
	text-decoration: none;
}
p:hover,
a:hover {
	text-decoration: underline;
}
.section {
	margin: 10px;
	margin-bottom: 30px;
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}
</style>
