import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App'
import { routes } from './router/routes'

Vue.use(VueRouter)

export const eventBus = new Vue({})

const router = new VueRouter({
	routes,
	mode: 'history'
})

new Vue({
	el: '#app',
	router,
	components: { App },
	render: (h) => h(App)
}).$mount('#app')
