<template>
	<div class="wrapper">
		<div class="col relative">
			<app-box
				v-for="(box, index) in boxes"
				:key="index"
				:inside="box"
				@checkColor="randomColor"
				@sendContent="showContent"
				@closedModule="hideModule"
			></app-box>
			<a class="last-project" href="https://di-daily.com" target="_blank">
				newest
			</a>
		</div>
		<div class="col abs">
			<app-author></app-author>
		</div>
		<div class="shuffle">
			<button class="button" @click="shuffle">Shuffle!</button>
			<img src="../assets/arrow.png" class="arrow" />
		</div>
		<transition name="fade" mode="in-out">
			<component :is="activeModule"></component>
		</transition>
	</div>
</template>

<script>
import Author from './Author'
import Box from './Box'

import Projects from './Projects'
import Skills from './Skills'
import Contact from './Contact'

import { eventBus } from '../main'

export default {
	data() {
		return {
			boxes: ['projects', 'skills', 'contact'],
			activeModule: ''
		}
	},
	components: {
		appAuthor: Author,
		appBox: Box,
		projects: Projects,
		skills: Skills,
		contact: Contact
	},
	methods: {
		randomColor() {
			const colorArray = ['#D95E32', '#4982A6', '#736A63']
			const boxes = document.querySelectorAll('.box')
			boxes.forEach((box) => {
				let colorNumber = Math.floor(Math.random() * colorArray.length)
				box.style.background = colorArray[colorNumber]
				colorArray.splice(colorNumber, 1)
			})
		},
		showContent(data) {
			setTimeout(() => {
				this.activeModule = data
			}, 400)
		},
		hideModule() {
			this.activeModule = ''
		},
		shuffle() {
			eventBus.$emit('clickShuffle')
			this.randomColor()
		}
	}
}
</script>

<style scoped>
.wrapper {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.col {
	width: 50%;
	height: 100%;
}
.abs {
	position: absolute;
	top: 0;
	right: 0;
	z-index: -1;
}
.relative {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	position: relative;
}
.button {
	position: absolute;
	top: 0;
	left: 0;
	background: none;
	margin: 15px 35px;
	padding: 5px;
}
.arrow {
	display: block;
	width: 100px;
	position: absolute;
	top: 10px;
	left: 100px;
}
.fade-enter {
	opacity: 0;
}
.fade-enter-active {
	transition: all 0.3s ease-in-out;
}
.fade-leave-active {
	transition: 0.2s all ease-in-out;
	opacity: 0;
}
.last-project {
	background: #16a637;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.3s ease-in-out;
	border-radius: 50%;
	cursor: pointer;
	z-index: 9;
	font-weight: 700;
	width: 150px;
	height: 150px;
	top: 30px;
	right: 30px;
	text-decoration: none;
	color: inherit;
}
@media (max-width: 720px) {
	.wrapper {
		flex-direction: column;
	}
	.col {
		margin-top: 20%;
		width: 100%;
		height: auto;
		min-height: 40%;
	}
	.relative {
		order: 1;
		margin-top: 40%;
	}
	.shuffle {
		display: none;
	}
	.last-project {
		right: 5px;
	}
}
</style>
