<template>
	<div class="wrapper">
		<h1>Marcin Chlewicki</h1>
		<h5>Frontend Developer</h5>
	</div>
</template>
<script>
export default {}
</script>
<style scoped>
.wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: var(--author-color);
}
</style>
